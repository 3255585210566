import React from "react";
import Typography from "@mui/material/Typography";

function Aanmelden() {
  return (
    <div style={{ maxWidth: "1040px", marginLeft: "10%", marginTop: "10pt" }}>
      <Typography variant="h5">Aanmelden</Typography>
      <Typography variant="body1" sx={{ marginBottom: 4 }}>
        Aanmeldprocedure: <br />
        Mocht je je kind bij de Windroos willen aanmelden, dan maken wij altijd
        graag een kennismakingsafspraak. Dit gesprek kun je aanvragen door een
        mail te sturen naar{" "}
        <a href="mailto:vernieuwingsschool@windroos.nl">
          vernieuwingsschool@windroos.nl
        </a>{" "}
        of door te bellen met 0343-592798. <br />
        <br />
        Jonneke, onze directeur,
        <br />
        <img
          src="https://wrassets.boss-tech.nl/upload/PortretfotoJonneke.jpeg"
          alt="JonnekeReichert"
          style={{ width: "20%", borderRadius: "5%" }}
        />
        <br />
        maakt graag een afspraak. <br />
        <br /> Na de kennismaking kun je je kind bij de Windroos aanmelden
        middels het formulier dat tijdens het kennismakingsgesprek is
        meegegeven. Als we een aanmelding ontvangen, neemt Marijke, onze intern
        begeleider, contact met jullie op om nog een aantal aanvullende vragen
        te stellen en om te bevestigen dat de aanmelding in goede orde is
        ontvangen. Zes weken voordat het kind 4 jaar wordt, neemt de
        kleuterleerkracht contact met jullie op om een kennismaking te plannen,
        waarbij jullie als ouders worden geïnformeerd over schoolse zaken en
        waarbij het wennen in de groep zal worden afgestemd.
      </Typography>
      <img
        src="https://wrassets.boss-tech.nl/beelden/natuurlijkspelen2.jpg"
        alt="aanmelden"
        style={{ width: "100%", borderRadius: "5%" }}
      />
    </div>
  );
}

export default Aanmelden;
